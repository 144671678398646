<template>
    <div class="website">
        <div class="wrap">
            <h1 class="title wow animated fadeInDown">
                <span class="gradient">{{$t('website.index.problem')}}</span>
            </h1>

            <div class="item wow animated fadeInUp">
                <p class="question">
                    <span class="gradient">Q1.{{$t('website.problem.question1')}}</span>
                </p>
                <p class="answer">{{$t('website.problem.answer1')}}</p>
            </div>
            
            <div class="item wow animated fadeInUp">
                <p class="question">
                    <span class="gradient">Q2.{{$t('website.problem.question2')}}</span>
                </p>
                <p class="answer">{{$t('website.problem.answer2')}}</p>
            </div>
            
            <div class="item wow animated fadeInUp">
                <p class="question">
                    <span class="gradient">Q3.{{$t('website.problem.question3')}}</span>
                </p>
                <p class="answer">{{$t('website.problem.answer3')}}</p>
            </div>
            
            <div class="item wow animated fadeInUp">
                <p class="question">
                    <span class="gradient">Q4.{{$t('website.problem.question4')}}</span>
                </p>
                <p class="answer">{{$t('website.problem.answer4')}}</p>
            </div>
            
            <div class="item wow animated fadeInUp">
                <p class="question">
                    <span class="gradient">Q5.{{$t('website.problem.question5')}}</span>
                </p>
                <p class="answer">{{$t('website.problem.answer5')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs'
export default {
    mounted(){
        this.wow();
    },
    methods:{
        wow(){
            if (! (/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                var wow = new WOW({
                    boxClass:'wow', 
                    animateClass: 'animated',
                    offset:50,
                    mobile:true,
                    live:true
                });
                this.$nextTick(() => {
                    wow.init();
                });
            };
        },
    },
}
</script>
<style scoped="scoped" lang="stylus">
	@import './problem.styl';
	@import '~@/assets/style/frames.styl';
</style>